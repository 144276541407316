<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="550px"  
  >
    <v-card class="grey lighten-2">
      <v-card-title class="justify-center colorCustom2--text">
        <div class="text-subtitle-2 text-center pt-n12" >{{ mensaje}} </div>
      </v-card-title>

      <v-card-actions class="justify-center">
        <botonCerrar @click.native="agree"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    components: {
      botonCerrar: () => import('./botonCerrar.vue'),
    },
    data () { return {
        show: false,
        mensaje : '',
    }},    
    methods: {
      open (mensaje) {
        this.show = true  
        this.mensaje = mensaje
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree () {
        this.resolve(true)
        this.show = false
      },
    },    
  }
</script>